.action-btn-common {
  padding: 1rem 2rem;
  background: #ff1493;
  font-family: "Proxima Nova";
  font-style: normal;
  font-size: 1.8rem;
  color: #ffffff;
  width: fit-content;
  letter-spacing: 0.1rem;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: top 0.5s;
}

.action-btn-common:hover {
  background: #ad005d;
  position: relative;
  top: -0.1rem;
}

.logo-text {
  font-family: cursive;
  font-style: normal;
  font-weight: 500;
  font-size: 2.5rem;
  color: #303031;
}
