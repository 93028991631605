.custom-table-outer {
  box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
  background-color: #fff;
  position: relative;

  &:hover .custom-table-pagination {
    opacity: 1 !important;
  }

  .d-none {
    display: none !important;
  }

  .widget-heading {
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;
  }

  .download-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: darken(#04a9f5, 8.5%);
    cursor: pointer;
  }

  .menu-list-wrapper {
    position: absolute;
    z-index: 1000;
    padding-top: 0rem;
    pointer-events: auto;
    right: 1rem;
    top: 3.5rem;

    ul {
      list-style: none;
      margin: 0rem;
      padding: 0.5rem 0rem;
      box-shadow: rgb(136, 136, 136) 0.3rem 0.3rem 1rem;
      border: 0.1rem solid rgb(153, 153, 153);
      background: rgb(255, 255, 255);

      li {
        cursor: pointer;
        padding: 0.5em 1em;
        color: rgb(51, 51, 51);
        background: none;
        font-size: 1.1rem;
        transition: background 250ms ease 0s, color 250ms ease 0s;
        display: flex;
        align-items: center;

        &.menu-item-heading {
          cursor: default;
          pointer-events: none;
          padding: 0;
          padding-left: 0.5rem;
          font-weight: 700;
        }
      }

      .menu-item-content {
        max-height: 20rem;
        overflow: auto;
        max-width: 30rem;
      }
    }
  }

  .menu-icon {
    position: absolute;
    right: 0.8rem;
    top: 1rem;
    color: #666666;
    font-size: 1.7rem;
    cursor: pointer;
    padding: 0.3rem 0.4rem;
    border-radius: 0.2rem;
    font-weight: 600;
  }

  .menu-icon:hover {
    background-color: #e6e6e6;
  }

  .filter-refresh {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 100;
    color: darken(#04a9f5, 8.5%);
    cursor: pointer;
  }

  .cutom-table-main {
    min-height: 40rem;
    max-height: 75vh;
    overflow: auto;
    background-color: #fff;

    &.on-full-screen {
      max-height: 90vh !important;
    }
    .custom-table-wrapper {
      min-width: 100%;
      width: fit-content;
      .visibility-hidden {
        visibility: hidden;
      }

      .loading-spiner {
        position: relative;
        top: 10;
        left: 10;
      }

      .table {
        border-collapse: unset;

        p {
          margin: 0;
        }
      }

      .table td,
      .table th {
        padding: 0.7rem 1.2rem;
      }

      .table td {
        border-color: #fbfbfb;
      }

      .table th {
        color: #898989;
      }

      .custom-table {
        .total-row {
          font-weight: 700;

          td:nth-child(1) {
            z-index: 100 !important;
          }

          td {
            position: sticky;
            bottom: 0;
            z-index: 99;
            background-color: #f4faff;
            color: #898989;
            border: 0.1rem solid #cecece;
          }
          td::before {
            content: " ";
            position: absolute;
            left: 0;
            top: 0;
            height: 0.2rem;
            width: 100%;
            background: lighten(#455a64, 50%);
          }
        }
        .parent-header {
          text-align: center;
          height: 3.4rem;
        }
        .parent-header::before {
          background: transparent !important;
        }
        .sticky-headers > th {
          position: sticky;
          top: 0.2rem;
          z-index: 101;
          transform: translateY(-0.21rem);
        }

        .sticky-headers > th::before {
          content: " ";
          position: absolute;
          left: 0;
          bottom: 0rem;
          height: 0.2rem;
          width: 100%;
          background: lighten(#455a64, 50%);
        }

        .sticky-subheaders > th {
          position: sticky;
          top: 3.9rem;
          z-index: 101;
          transform: translateY(-0.21rem) !important;
        }

        .sticky-subheaders > th::before {
          content: " ";
          position: absolute;
          left: 0rem;
          bottom: 0rem;
          height: 0.2rem;
          width: 100%;
          background: lighten(#455a64, 50%);
        }

        &.sticky-row {
          tr:nth-child(1) > th:nth-child(1):not(.td-grouped) {
            position: sticky;
            left: 0.2rem;
            z-index: 999 !important;
            border: 0.1rem solid #cecece;
            transform: translate(-0.21rem, -0.21rem);
          }
          // tr.sticky-subheaders > th:nth-child(1):not(.td-grouped) {
          //     position: sticky;
          //     left: 0rem;
          //     z-index: 998 !important;
          // }
          tr > td:nth-child(1):not(.td-grouped) {
            position: sticky;
            left: 0.2rem;
            // z-index: 998 !important;
            z-index: 1;
            background-color: #f4faff;
            border: 0.1rem solid #cecece;
            transform: translateX(-0.21rem);
          }

          tr:nth-child(1)
            > th:nth-child(1):not(.td-grouped):not(.primary-col-data):not(
              .primary-col-data-alt
            ):after,
          tr
            > td:nth-child(1):not(.td-grouped):not(.primary-col-data):not(
              .primary-col-data-alt
            )::after {
            content: " ";
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 0.3rem;
            background: lighten(#455a64, 50%);
            transform: translateX(0.1rem);
          }
        }

        &.sticky-last-col-row {
          tr:nth-child(1) > th:last-child {
            position: sticky;
            right: 0rem;
            z-index: 999 !important;
            border: 0.1rem solid #cecece;
            transform: translate(-0.21rem, -0.21rem);
          }

          tr > td:last-child {
            position: sticky;
            right: 0rem;
            z-index: 1;
            background-color: #f4faff;
            border: 0.1rem solid #cecece;
            transform: translateX(-0.21rem);
          }

          tr:nth-child(1) > th:last-child:after,
          tr > td:last-child::after {
            content: " ";
            position: absolute;
            left: -0.1rem;
            bottom: 0;
            height: 100%;
            width: 0.3rem;
            background: lighten(#455a64, 50%);
            transform: translateX(0.1rem);
          }

          .total-row {
            td:last-child {
              z-index: 100 !important;
            }
          }
        }

        tr > td {
          color: #000;
          vertical-align: middle;

          &.primary-col-data,
          &.primary-col-data-alt {
            background-color: #bbb !important;
            color: white;
            letter-spacing: 0.25rem;
          }

          .content-ul > li {
            list-style-type: circle;
          }
          sub {
            color: darken(#04a9f5, 8.5%);
            white-space: nowrap;
          }

          sub:hover {
            cursor: pointer !important;
            text-decoration: underline;
          }
        }

        tr > th {
          .column-header-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .column-header {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .multilineTitle {
              padding-left: 1rem;
            }
          }

          &:hover > * {
            position: relative;
          }

          background: #f4faff !important;
          vertical-align: middle;
          color: #898989;
          font-size: 1.2rem;
          letter-spacing: 0.15rem;
          border: 0.1rem solid #cecece;
          .dd-filter-items {
            max-height: 20rem;
            overflow: auto;
            margin-left: 0.9rem;
          }

          .dropdown-menu {
            width: auto;
            min-width: 30rem;
            padding-bottom: 1rem;
          }

          .sortable-icons {
            i {
              display: inline-block;
              cursor: pointer;
              transition: all 300ms;
            }

            i.sorted-asc {
              padding: 0rem 0.3rem;
              width: fit-content;
              opacity: 1;
              color: lighten(#1434a4, 15%);
            }

            i.sorted-desc {
              padding: 0rem 0.3rem;
              width: fit-content;
              opacity: 1;
              color: lighten(#1434a4, 15%);
            }

            i:hover {
              opacity: 1;
              color: lighten(#1434a4, 10%);
            }
            i:last-child {
              margin-left: -0.6rem;
            }
          }

          .column-header:hover .sortable-icons > i {
            padding: 0;
            width: fit-content;
          }

          .filterable-icon {
            margin-left: 0.6rem;

            i {
              cursor: pointer;
            }

            i:hover {
              color: lighten(#1434a4, 10%);
            }

            .filtered {
              color: lighten(#1434a4, 10%);
            }
          }

          .dd-container {
            display: inline-block;

            .dd-filter {
              text-decoration: none;
              color: #898989;
              &::after {
                display: none;
              }
              padding-top: 0;
              padding-bottom: 0;
              margin: 0;
            }

            ul {
              margin: 0;
            }
          }
        }

        .td-expendable {
          max-width: 30vw;
          white-space: break-spaces;
        }

        .td-image {
          padding: 0.1rem 0.45rem;
          text-align: center;
        }

        .td-image-header {
          padding: 0.7rem 4rem;
          text-align: center;

          .column-header-wrapper {
            justify-content: center !important;
          }
        }

        .td-json {
          position: relative;

          ::-webkit-scrollbar {
            width: 0.6rem;
            height: 0.6rem;
            background-color: transparent;
          }

          ::-webkit-scrollbar-thumb {
            height: 0.6rem;
            border: 0rem solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 0.7rem;
            background-color: #999;
          }
          .copy-btn {
            color: darken(#04a9f5, 8.5%);
            cursor: pointer;
            position: absolute;
            right: 1.5rem;
            top: 0.5rem;
            z-index: 100;
          }
          pre {
            max-height: 20rem;
            max-width: 50rem;
            position: relative;
          }
        }

        .td-anchor {
          text-align: center;
        }
        .td-img-href {
          color: #1434a4 !important;
          width: 3.5rem;
          height: 3.5rem;
          position: relative;
          top: 1rem;
          padding: 0rem 0.8rem;
        }
        .td-img {
          object-fit: cover;
          width: 3.5rem;
          border-radius: 0.3rem;
          cursor: pointer;

          &.img-hoverable:hover {
            cursor: pointer;
            position: relative;
            transform: scale(1.5);
            transition: all 2s;
          }
        }

        .td-href {
          color: #1434a4 !important;
          cursor: pointer;
        }

        .td-href-fade {
          color: #7e96ee !important;
          cursor: pointer;
        }

        .dd-filter-actions {
          float: right;
        }

        .dd-filter-text {
          min-width: 20rem;
          max-width: 35rem;
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
          padding-left: 0.5rem;
          padding-bottom: 0.3rem;
        }

        .dd-filter-item {
          display: flex;
          align-items: flex-start;
          min-width: 25rem;
          input[type="checkbox"] {
            position: relative;
            top: 0.7rem;
          }
          .form-control {
            width: 95%;
            padding: 0.5rem 1rem;
          }

          .form-control:focus {
            outline: none;
            box-shadow: none;
          }
        }
        .dropdown-menu > li.active,
        .dropdown-menu > li:active,
        .dropdown-menu > li:focus,
        .dropdown-menu > li:hover {
          background: transparent;
        }

        .btn-outline-dark:hover {
          background-color: #fff;
          color: #37474f;
          border-color: #37474f;
        }
      }
      .footer-components {
        float: left;
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: 98;
        background-color: white;
        box-shadow: 0.1rem 0.2rem 1.3rem #bbb;
        border-radius: 0.4rem;
      }
    }
  }
  .display-none {
    display: none !important;
  }

  .filter-box-input {
    padding: 0.5rem 1rem;
  }

  .filter-box-input:focus {
    outline: none;
    box-shadow: none;
  }

  .fade-text {
    color: #bbb;
  }

  .range-min-max {
    cursor: pointer;
    color: darken(#04a9f5, 8.5%);
  }

  tbody tr {
    background-color: #f2f3fa !important;
  }

  .custom-table-pagination {
    opacity: 0;
    float: right;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1000;
    transition: all 1s;
    margin: 0.5rem;
    .page-item .page-link {
      background-color: #bec4d5 !important;
      color: white;
    }

    .page-item .page-link:focus {
      box-shadow: none;
    }

    .page-item.active .page-link {
      background-color: lighten(#1dc4e9, 40%) !important;
      color: black !important;
      border-color: lighten(#1dc4e9, 40%) !important;
    }

    .page-item.disabled .page-link {
      background-color: lighten(#3f4d67, 10%);
      color: white;
    }
    .page-item.active.disabled .page-link {
      background-color: #1dc4e9;
      color: white;
    }
  }
  .pagination-display-none {
    display: none;
  }
}
.image-slider {
  .modal-content {
    background: transparent;
    border: none;
    box-shadow: none;

    .carousel-modal.modal-body {
      // height: 40vw !important;
      max-height: 40vw !important;
      width: 100%;
      padding: 0rem !important;
      // width: fit-content;
      .carousel.slide {
        // height: 40vw !important;
        max-height: 40vw !important;
        // width: fit-content;
        .carousel-inner {
          // height: 40vw !important;
          max-height: 40vw !important;
          // width: fit-content;
          .carousel-item {
            // height: 40vw !important;
            max-height: 40vw !important;
            margin: auto;
            // width: fit-content;
            img {
              max-height: 40vw !important;
              object-fit: cover;
              margin: auto;
            }
            video {
              object-fit: cover;
              width: inherit;
              // height: 40vw !important;
            }
          }
        }

        .carousel-control-next {
          height: 5rem;
          margin: auto;
          border-radius: 50%;
          width: 5rem;
          background: #aaa;
        }

        .carousel-control-prev {
          height: 5rem;
          margin: auto;
          border-radius: 50%;
          width: 5rem;
          background: #aaa;
        }
      }
    }
  }
}
.col-p {
  &-red {
    background: lighten($color: green, $amount: 3%);
    color: white;
  }
  &-orange {
    background: lighten($color: green, $amount: 6%);
    color: white;
  }
  &-lightgreen {
    background: lighten($color: green, $amount: 9%);
    color: white;
  }
  &-green {
    background: lighten($color: green, $amount: 12%);
    color: white;
  }
}

.col-img-thumbnail {
  width: 2rem;
  height: 2rem;
  position: relative;
}

.col-img-thumbnail img {
  width: 100%;
  border-radius: 0.3rem;
}

.col-img-thumbnail img:hover {
  transform: scale(1.4);
}

.singleImage {
  .carousel-indicators,
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
}

.cms-container {
  position: relative;
  ul {
    margin: 0 !important;
  }
  .cms-filter {
    border-bottom: 0.3rem solid #038cca;
    margin: 0 !important;
    background: white;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    font-family: "Roboto";
    font-size: 1.3rem;
    font-weight: 400;
    &.dropdown-toggle::after {
      margin-left: 1rem;
    }
    .cms-badge {
      position: relative;
      top: -1rem;
      color: white;
      background: #4171a3;
    }
  }
  .cms-menu {
    padding: 1rem 2rem;
    min-width: 35rem;
    .cms-filter-items {
      .cms-filter-item {
        .cms-filter-box-input {
          padding: 0.5rem 1rem;
        }
        input[type="checkbox"] {
          margin-right: 0.8rem;
        }
      }
    }
  }

  .cms-menu-lg {
    padding: 1rem 2rem;
    min-width: 40rem;
    .cms-filter-items {
      .cms-filter-item {
        .cms-filter-box-input {
          padding: 0.5rem 1rem;
        }
        input[type="checkbox"] {
          margin-right: 0.8rem;
        }
      }
    }
  }
  .cms-display-none {
    display: none;
  }
  .cms-menu-items {
    max-height: 50vh;
    overflow: auto;
  }
}

.widget-footer {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .feedback-options {
    padding: 0.5rem 0.1rem 0.5rem 0.8rem;
    font-size: 1.8rem;
    border-radius: 0.4rem;
  }
  .footer-text {
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
    border-radius: 0.4rem;
    text-align: end;
    code {
      font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
        sans-serif;
      color: black;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
}

.link-color {
  color: #1434a4 !important;
}

.hover-blue:hover {
  color: #1434a4 !important;
}

.feedback-title-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.w-500 {
  width: 50rem;
}
.w-300 {
  width: 30rem;
}
.w-150 {
  width: 15rem;
}
.w-200 {
  width: 20rem;
}

.fw-right {
  font-weight: lighter;
}

.conversation-box {
  background-color: #fff;
  max-width: 60rem;
  margin: auto;
}

.h-35vh {
  height: 35vh;
}

.h-45vh {
  height: 45vh;
}

.box-shadow-title {
  box-shadow: 0.1rem 0.1rem 0.2rem #548ae0;
  padding: 1rem;
  background: #1434a4;
  color: white;
  height: 4.1rem;
}

.feedback-thread-action {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.1rem;
}

.conversation-box {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  position: relative;
  border-radius: 0.6rem;
}

.send-icon {
  padding: 0.8rem 1.2rem;
  background-color: #273338;
  color: #fff;
  border-radius: 0.6rem;
}

.send-icon-light {
  padding: 0.8rem 1.2rem;
  background-color: lighten(#273338, 15%);
  color: #fff;
  border-radius: 0.6rem;
}

.conv-chatbox {
  height: calc(35vh - 7.2rem);
}

.conv-chatbox-admin {
  height: calc(45vh - 7.2rem);
}

.align-self-right {
  align-self: flex-end;
}

.paper-card {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  border-radius: 0.4rem;
  overflow: auto;
  position: relative;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  p {
    font-size: 1rem;
  }
}

.custom-pannel {
  &.bg-white {
    background: #fff;
  }

  &.bg-offwhite {
    background: #eee;
  }
  padding: 2rem;
  padding-bottom: 6rem;
  position: relative;
  border-radius: 0.4rem;
  box-shadow: 0.3rem 0.3rem 1.2rem 0.1rem #ccc;
  &__header {
    min-height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .custom-pannel-heading {
      font-size: 2.4rem;
      font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
        sans-serif;
      padding: 0.5rem 1rem;
      color: #000;
      line-height: 1.5;
      letter-spacing: 0.15rem;
      text-align: center;
      margin: 0;
    }
  }

  &__body {
    min-height: 40rem;
  }

  &.height-auto &__body {
    min-height: auto !important;
  }

  &__footer {
    .widget-footer {
      left: 0rem;
    }
  }

  &.bg-offwhite &__footer {
    .widget-footer {
      background-color: #eee;
    }
  }
}

.bg-alt1 {
  background: #f1ffff;
  border-left: 0.5rem solid #50ffff;
}

.bg-alt2 {
  background: #fff1ff;
  border-left: 0.5rem solid #ff50ff;
}

.bg-alt3 {
  background: #fffff1;
  border-left: 0.5rem solid #ffff50;
}

.bg-alt4 {
  background: rgb(180, 170, 240);
  border-left: 0.4rem solid rgb(0, 50, 200);
}

.bg-alt5 {
  background: antiquewhite;
  border-left: 0.4rem solid orange;
}

.pannel-card-abs {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0.5rem 1rem;
}

.pannel-header-component {
  padding: 1rem;
  text-align: center;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  color: #000;
  border-radius: 0rem 0.4rem 0.4rem 0rem;

  * {
    margin: 0;
  }

  &__title {
    font-weight: bold;
    font-family: "roboto";
    font-size: 1.1rem;
    letter-spacing: 0.11rem;
  }

  &__value {
    font-family: "roboto";
    font-size: 0.9rem;
  }
}

.custom-avatar-editor {
  display: flex;
  justify-content: center;
  color: #000;
  .avatar-editor-box {
    text-align: center;
  }
  label {
    font-weight: 600;
  }
}

.blog-assets {
  img {
    height: 5rem;
    width: 5rem;
    object-fit: cover;
  }

  .dropdown-menu {
    padding: 0;
  }

  ul {
    padding: 0 2rem !important;
  }
}

@keyframes moveInButton {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.btn-req-feature,
.btn-req-feature:link,
.btn-req-feature:visited {
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  width: fit-content;
  border-radius: 3rem;
  transition: all 0.2s;
  position: relative;
  border: none;
  background-color: white;
  color: #2caf82;
  font-weight: 600;
  padding: 1.2rem 2.5rem;
  height: fit-content;
  text-align: center;
  cursor: pointer;
  animation: moveInButton 0.5s ease-out 1s;
  animation-fill-mode: backwards;
  line-height: 1;
  font-size: 1.4rem;
}

.btn-req-feature:hover,
.btn-req-feature:focus {
  outline: none;
  transform: translateY(-0.2rem);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.btn-req-feature:hover::after,
.btn-req-feature:focus::after {
  transform: scaleY(1.3) scaleX(1.6);
  opacity: 0;
  background-color: white;
}

.btn-req-feature::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.table-stickyRow2 {
  tr > th:nth-child(1):not(.td-grouped) > span {
    width: 13.7rem;
  }

  tr > th:nth-child(2):not(.td-grouped) {
    position: sticky;
    left: 16.3rem;
    // z-index: 999 !important;
    z-index: 102 !important;
    border: 0.1rem solid #cecece;
    transform: translate(-0.21rem, -0.21rem);
  }
  tr.sticky-subheaders > th:nth-child(2):not(.td-grouped) {
    position: sticky;
    left: 16.5rem;
    // z-index: 998 !important;
  }
  tr > td:nth-child(2):not(.td-grouped) {
    position: sticky;
    left: 16.5rem;
    // z-index: 998 !important;
    background-color: #f4faff;
    border: 0.1rem solid #cecece !important;
    transform: translateX(-0.21rem);
  }

  tr:nth-child(1)
    > th:nth-child(2):not(.td-grouped):not(.primary-col-data):not(
      .primary-col-data-alt
    ):after,
  tr
    > td:nth-child(2):not(.td-grouped):not(.primary-col-data):not(
      .primary-col-data-alt
    )::after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 0.3rem;
    background: lighten(#455a64, 50%);
    transform: translateX(0.1rem);
  }

  .total-row {
    td:nth-child(2) {
      z-index: 100 !important;
    }
  }
}

.accordion-wrapper {
  border-radius: 1rem 1rem 0rem 0rem;
  background-color: #f1ffff;
  border-bottom: 0.2rem solid #50ffff;

  .heading {
    font-size: 2.5rem;
    font-weight: 300;
    color: #183b56;
  }
  .accordion-title {
    font-size: 3.2rem;
    font-weight: 700;
  }
  .accordion {
    background-color: #f3faf7;
  }

  .heading-flex {
    cursor: pointer;
  }
  .desc {
    font-size: 1.6rem;
    font-weight: 500;
  }
}

.slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  .slider-main {
    display: flex;
    align-items: center;
    justify-content: center;
    .slider {
      width: 20rem;
    }
    .slider-text {
      font-size: 1.8rem;
      font-family: "Roboto";
    }
  }
}

.dd-filter-text {
  min-width: 20rem;
  max-width: 35rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  padding-left: 0.5rem;
  padding-bottom: 0.3rem;
}

.dd-filter-item {
  display: flex;
  align-items: flex-start;
  min-width: 25rem;
  input[type="checkbox"] {
    position: relative;
    top: 0.7rem;
  }
  .form-control {
    width: 95%;
    padding: 0.5rem 1rem;
  }

  .form-control:focus {
    outline: none;
    box-shadow: none;
  }
}

.dd-filter-items {
  max-height: 20rem;
  overflow: auto;
  margin-left: 0.9rem;
}

.range-min-max {
  cursor: pointer;
  color: darken(#04a9f5, 8.5%);
}

.switch {
  position: relative;
  display: inline-block;
  width: 6rem;
  height: 3.4rem;
  margin: 0;

  input {
    display: none;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 2.6rem;
    width: 2.6rem;
    left: 0.4rem;
    bottom: 0.4rem;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 0.1rem #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(2.6rem);
    -ms-transform: translateX(2.6rem);
    transform: translateX(2.6rem);
  }

  .slider.round {
    border-radius: 3.4rem;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.pricing {
  background-color: #f5f5f5;

  .pricing-deatails {
    color: #2caf82;
    font-weight: 600;
    font-size: 1.8rem;
  }
  .price span {
    font-size: 3rem;
    font-weight: 700;
    margin-right: 1rem;
  }
  .price-deatils {
    font-size: 2rem;
    font-weight: 700;
  }
  .pricing-grid {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
  }
  .color {
    color: #2caf82;
  }
  .btn-withoutselect .fa-arrow-right {
    color: rgb(37, 34, 34);
  }
  .btn-withselect .fa-arrow-right {
    color: rgb(255, 255, 255);
  }
  .btn-withoutselect {
    background-color: transparent;
    padding: 1rem 1.8rem;
    font-weight: 500;
    border: 0.1rem solid #5a7184;
    outline: none;
    border-radius: 2.5rem;
  }
  .pricing-grid-item {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    justify-content: space-between;
    min-width: 40rem;
  }
  .btn-withselect {
    background-color: #2caf82;
    padding: 1rem 1.8rem;
    color: white;
    outline: none;
    border: none;
    font-weight: 500;
    border: 0.1rem solid none;
    border-radius: 2.5rem;
  }
  @media (max-width: 1000px) {
    .pricing-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 574px) {
    .pricing-grid {
      grid-template-columns: 1fr;
    }
  }

  .pricing-header-title {
    font-size: 3.5rem;
    font-weight: 700;
  }
  .pricing-header-title span {
    color: #36b37e;
  }
  .form-check .form-check-input {
    float: none;
    font-size: 1.8rem;
  }
  .form-check .form-check-input:focus {
    box-shadow: none;
  }
  .form-check-input:checked {
    background-color: #36b37e;
    border-color: #36b37e;
    background-position: left center;
  }
  .package-title {
    color: #36b37e;
    font-weight: 700;
    font-size: 3.5rem;
  }

  .border-green {
    border: 0.1rem solid #36b37e;
  }
  .monthly-color {
    color: gray;
    font-weight: 700;
  }
  .monthly {
    color: black;
    font-weight: 700;
  }
  .price {
    font-weight: 700;
  }
}

.custom-chart-outer {
  &:hover .custom-chart-pagination {
    opacity: 1 !important;
  }

  .pagination-wrapper {
    position: absolute;
    bottom: 2.5rem;
    right: 1rem;
    z-index: 999;
  }

  .custom-chart-pagination {
    opacity: 0;
    float: right;
    position: sticky;
    bottom: 0;
    right: 0;
    z-index: 100;
    transition: all 1s;
    margin: 0;
    .page-item .page-link {
      background-color: #bec4d5 !important;
      color: white;
    }

    .page-item .page-link:focus {
      box-shadow: none;
    }

    .page-item.active .page-link {
      background-color: lighten(#1dc4e9, 40%) !important;
      color: black !important;
      border-color: lighten(#1dc4e9, 40%) !important;
    }

    .page-item.disabled .page-link {
      background-color: lighten(#3f4d67, 10%);
      color: white;
    }
    .page-item.active.disabled .page-link {
      background-color: #1dc4e9;
      color: white;
    }
  }
  .pagination-display-none {
    display: none;
  }
}

.drillback-button {
  color: rgb(51, 51, 51);
  padding: 0.7rem 1.4rem;
  border: 0.1rem solid rgb(204, 204, 204);
  background-color: rgb(247, 247, 247);
  position: absolute;
  z-index: 1000;
  border-radius: 0.2rem;
  right: 0;
  top: 5rem;
}

.drillback-button-for-table {
  color: rgb(51, 51, 51);
  padding: 0.7rem 1.4rem;
  border: 0.1rem solid rgb(204, 204, 204);
  background-color: rgb(247, 247, 247);
  border-radius: 0.2rem;
}

.selected-option-val {
  background-color: blue;
}

.sk-wrapper {
  padding: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 100%;
  .sk-head {
    align-self: flex-start;
  }
  .sk-body {
    align-self: center;
    max-height: 40rem;
    overflow: hidden;
  }
  .sk-foot {
    align-self: flex-end;
  }
  .sk-local-filter {
    width: 100%;
    overflow: hidden;
  }

  .sk-table {
    width: 100%;
    overflow: hidden;
    .sk-row {
      width: 100%;
      white-space: nowrap;
      line-height: 1;
      .sk-col {
        width: 15rem;
        padding: 0.5rem;
        display: inline-block;
      }
    }
  }

  .sk-chart {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-align: center;
    .sk-column {
      display: inline-block;
      padding: 0.5rem;
    }
  }

  .sk-bar {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    height: 100%;
    .sk-bar-val {
      padding: 0.5rem;
    }
  }
  .sk-area {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    height: 100%;
    .sk-area-bar {
      display: inline-block;
      * {
        border-radius: 10rem;
      }
    }
  }
  .sk-line {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    height: 100%;
    .sk-line-bar {
      display: inline-block;
      * {
        border-radius: 10rem;
      }
    }
  }
}

.marquee {
  width: 45rem;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.widget-heading-center {
  text-align: center;
}

.widget-heading {
  font-size: 1.8rem;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
  padding: 0.5rem 1rem;
  color: #455a64;
  line-height: 1.2;
}

#loading-modal {
  display: none;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  .svgContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  // background: rgba(255, 255, 255, 0.5) url("/loading.svg") 50% 50% no-repeat;
}

#error-modal {
  display: none;

  .modal-body p {
    margin: 0;
    padding: 2rem 1rem;
  }

  .modal-header,
  .modal-footer {
    padding: 1rem;
  }
}

body.loading,
body.loading-all {
  // overflow: hidden;
}

body.loading #loading-modal,
body.loading-all #loading-modal {
  display: block;

  .svgContainer {
    display: block !important;
  }
}

@keyframes bg {
  0% {
    background: linear-gradient(
      to right bottom,
      rgba(54, 88, 80, 0.75),
      rgba(29, 196, 233, 0.75)
    );
  }
  50% {
    background: linear-gradient(
      to right bottom,
      rgba(29, 196, 233, 0.75),
      rgba(54, 88, 80, 0.75),
      rgba(29, 196, 233, 0.75)
    );
  }
  100% {
    background: linear-gradient(
      to right bottom,
      rgba(54, 88, 80, 0.75),
      rgba(29, 196, 233, 0.75)
    );
  }
}

#redirecting-modal {
  display: none;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation: bg 2s infinite;
  background: linear-gradient(
    to right bottom,
    rgba(29, 196, 233, 0.75),
    rgba(54, 88, 80, 0.75),
    rgba(29, 196, 233, 0.75)
  );
}

body.redirect #redirecting-modal {
  display: block;
}

.please-wait {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: white;
}

body.body-error {
  overflow: hidden;
}

body.body-error .modal,
body.loading .modal,
body.loading-all .modal {
  background: rgba(0, 0, 0, 0.5);
}

body.body-error #error-modal {
  display: block !important;
}

#error-modal {
  z-index: 1000000;
}

.canvasjs-chart-credit {
  display: none !important;
}

.header-account {
  cursor: pointer;
  padding: 0.3rem 1rem;
  border-radius: 0.2rem;
  background-color: #3f4d67;
}

.span-dark {
  color: white;
  border: 0.1rem solid #999 !important;
  background-color: #888 !important;
  padding: 0.6rem 1.5rem;
  font-size: 1.6rem;
  box-shadow: 0.3rem 0.2rem 1rem #aaa;
}

.span-outline-dark {
  color: #a9b7d0;
}

.span-outline-dark:hover {
  color: #1434a4;
  position: relative;
  top: -0.1rem;
  box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.5);
}

.account-d-flex {
  display: flex !important;
  flex-direction: row !important;
}

.no-noti {
  padding: 1rem 2rem !important;
  opacity: 0.4;
  font-size: 1.3rem;
}

.widget-heading {
  font-size: 2rem;
  padding: 0.3rem 1rem;
}

.widget-heading-independent {
  text-align: center;
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  background-color: #fff;
  padding: 0.8rem 1rem;
}

.btn-link:hover,
.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
}

.close {
  background-color: transparent;
  border: none;
}

.prop-text-area {
  min-height: 50vh !important;
}
.prop-pre {
  min-height: 30vh !important;
  max-height: 50vh !important;
}

.lg-icon {
  font-size: 5rem;
}
.icon-warpper {
  position: relative;
}

.icon-left {
  cursor: pointer;
  position: absolute;
  left: 2rem;
}

.hover-gray:hover {
  background-color: #ccc;
}

.icon-right {
  font-size: 3rem;
  color: #0caee2;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 0.5rem;
}

.icon-right-wrapper {
  font-size: 3rem;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 0.5rem;
}

.icon-right:hover {
  color: #1434a4;
}

.icons-right {
  font-size: 3rem;
  color: #0caee2;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 0.5rem;
}

.icons-right i:hover {
  color: #1434a4;
}

.icons-right i.text-danger-light:hover {
  color: rgb(255, 0, 0) !important;
}

.text-danger-light {
  color: rgba(255, 0, 0, 0.7);
}

.right-text-rotated {
  font-size: 2rem;
  color: rgba(255, 0, 0, 0.6);
  position: absolute;
  top: 40%;
  right: 2rem;
  transform: rotate(-10deg);
  padding: 0.5rem 1.5rem;
  border: 0.2rem solid rgba(255, 0, 0, 0.6);
  z-index: 100;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
.right-text-rotated-blue {
  font-size: 2rem;
  color: rgba(0, 0, 255, 0.6);
  position: absolute;
  top: 40%;
  right: 2rem;
  transform: rotate(-10deg);
  padding: 0.5rem 1.5rem;
  border: 0.2rem solid rgba(0, 0, 255, 0.6);
  z-index: 100;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}
.prop-text-area {
  min-height: 50vh;
}
.prop-pre {
  min-height: 30vh;
  max-height: 50vh;
}

.WordClouds {
  height: 54.4rem !important;
}

.card-body .input-group > .form-control {
  padding: 0.7rem 2rem;
}

.link-blue {
  color: darken(#04a9f5, 8.5%) !important;
}

.link-white {
  color: #fff !important;
}

.tc {
  &_section_title {
    margin-bottom: 0.4rem;
    font-size: 1.8rem;
    font-weight: 600;
  }
  &_block {
    margin-bottom: 0.2rem;
  }
  &_subtitle {
    margin-bottom: 0.3rem;
    font-size: 1.25rem;
    font-weight: 600;
  }
  &_para {
    font-size: 1.1rem;
  }
  &_bold {
    font-weight: 600;
  }
  &_points > li {
    list-style-type: circle;
    font-size: 1.005rem;
  }
  &_cp {
    cursor: pointer;
  }
}

::selection {
  background-color: rgba(76, 175, 80, 0.6);
  color: white;
}

.color-black {
  color: black !important;
}

.color-less-black {
  color: #455a64 !important;
}

.f-1rem {
  font-size: 1rem;
}

.initCap {
  text-transform: capitalize;
}

.z-1000 {
  z-index: 1000;
}

.machine {
  width: 60vmin;
  fill: #8bc53f;

  .black-fill {
    fill: #010101;
  }

  .small-shadow,
  .medium-shadow,
  .large-shadow {
    fill: rgba(0, 0, 0, 0.05);
  }

  .small {
    -webkit-animation: counter-rotation 2.5s infinite linear;
    -moz-animation: counter-rotation 2.5s infinite linear;
    -o-animation: counter-rotation 2.5s infinite linear;
    animation: counter-rotation 2.5s infinite linear;
    -webkit-transform-origin: 10rem 22.5rem;
    -ms-transform-origin: 10rem 22.5rem;
    transform-origin: 10rem 22.5rem;
  }

  .small-shadow {
    -webkit-animation: counter-rotation 2.5s infinite linear;
    -moz-animation: counter-rotation 2.5s infinite linear;
    -o-animation: counter-rotation 2.5s infinite linear;
    animation: counter-rotation 2.5s infinite linear;
    -webkit-transform-origin: 11rem 23.5rem;
    -ms-transform-origin: 11rem 23.5rem;
    transform-origin: 11rem 23.5rem;
  }

  .medium {
    -webkit-animation: rotation 3.75s infinite linear;
    -moz-animation: rotation 3.75s infinite linear;
    -o-animation: rotation 3.75s infinite linear;
    animation: rotation 3.75s infinite linear;
    -webkit-transform-origin: 25.4rem 37.9rem;
    -ms-transform-origin: 25.4rem 37.9rem;
    transform-origin: 25.4rem 37.9rem;
  }

  .medium-shadow {
    -webkit-animation: rotation 3.75s infinite linear;
    -moz-animation: rotation 3.75s infinite linear;
    -o-animation: rotation 3.75s infinite linear;
    animation: rotation 3.75s infinite linear;
    -webkit-transform-origin: 26.4rem 38.9rem;
    -ms-transform-origin: 26.4rem 38.9rem;
    transform-origin: 26.4rem 38.9rem;
  }

  .large {
    -webkit-animation: counter-rotation 5s infinite linear;
    -moz-animation: counter-rotation 5s infinite linear;
    -o-animation: counter-rotation 5s infinite linear;
    animation: counter-rotation 5s infinite linear;
    -webkit-transform-origin: 46.1rem 17.3rem;
    -ms-transform-origin: 46.1rem 17.3rem;
    transform-origin: 46.1rem 17.3rem;
  }

  .large-shadow {
    -webkit-animation: counter-rotation 5s infinite linear;
    -moz-animation: counter-rotation 5s infinite linear;
    -o-animation: counter-rotation 5s infinite linear;
    animation: counter-rotation 5s infinite linear;
    -webkit-transform-origin: 47.1rem 18.3rem;
    -ms-transform-origin: 47.1rem 18.3rem;
    transform-origin: 47.1rem 18.3rem;
  }

  @-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  @-moz-keyframes rotation {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(359deg);
    }
  }
  @-o-keyframes rotation {
    from {
      -o-transform: rotate(0deg);
    }
    to {
      -o-transform: rotate(359deg);
    }
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @-webkit-keyframes counter-rotation {
    from {
      -webkit-transform: rotate(359deg);
    }
    to {
      -webkit-transform: rotate(0deg);
    }
  }
  @-moz-keyframes counter-rotation {
    from {
      -moz-transform: rotate(359deg);
    }
    to {
      -moz-transform: rotate(0deg);
    }
  }
  @-o-keyframes counter-rotation {
    from {
      -o-transform: rotate(359deg);
    }
    to {
      -o-transform: rotate(0deg);
    }
  }
  @keyframes counter-rotation {
    from {
      transform: rotate(359deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
}

.modal {
  backdrop-filter: blur(0.05rem);
}

.modal-open .modal {
  backdrop-filter: blur(0.3rem);
}

body > iframe {
  height: 0 !important;
  width: 0 !important;
}

.mh-300 {
  max-height: 30rem;
}

.mw-300 {
  max-width: 30rem;
}

.display-inline-block {
  display: inline-block;
}

.fade-na {
  opacity: 0.5;
}

.nav-tabs .nav-link {
  color: black;
}

.download-icon-right {
  position: absolute;
  right: 0rem;
  top: 1rem;
  color: darken(#04a9f5, 8.5%);
  cursor: pointer;
}

.icon-blue {
  color: darken(#04a9f5, 8.5%);
  cursor: pointer;
}

.hide-caret {
  &::after {
    display: none !important;
  }

  .header-account {
    position: relative;
    padding-right: 2.5rem;
  }

  .header-account::after {
    content: "\e842";
    font-family: "feather";
    font-size: 1.5rem;
    border: none;
    position: absolute;
    right: 0.5rem;
    top: 0.2rem;
  }
}

.schedult-task-grid {
  display: grid !important;
  grid-template-columns: 2.5fr 1fr;
  background: transparent;
  grid-gap: 1rem;
}

.grid-1-1fr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
}

.align-self-end {
  align-self: baseline;
}

.grid-1-1-1fr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5rem;
}

.cursor-pointer {
  cursor: pointer;
}
