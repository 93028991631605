.parent-tag {
  // background-color: #FFBCD9;
  padding: 10rem 0rem;
  .contributors-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0rem 5rem;

    .section-content {
      .section-logo {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }
      .sectiion-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 55rem;

        .section-heading {
          font-family: "Bely Display";
          font-style: normal;
          font-weight: 400;
          font-size: 4.8rem;
          line-height: 6.4rem;
          color: #303031;
        }
        .desc {
          font-family: "Proxima Nova";
          font-style: normal;
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 2.6rem;
          letter-spacing: -0.02em;
          color: #303031;
        }
      }
    }
    .section-cards {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      align-items: center;
      justify-content: center;

      .helper-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        background: #ffffff;
        border: 0.4rem solid #303031;
        border-radius: 1.6rem;
        padding: 1rem;
        box-shadow: 0rem 0.5rem 0.15rem #ff1493;
        overflow: hidden;

        .first-line {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .line-start {
            font-family: "Novel Mono Pro";
            font-style: normal;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 2.4rem;
            letter-spacing: -0.02em;
            color: #303031;
          }
          .share {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .action-btn {
              font-family: "Novel Mono Pro";
              font-style: normal;
              font-weight: 500;
              font-size: 1.6rem;
              line-height: 2.4rem;
              color: #ffffff;
              background: #ff1493;
              border-radius: 10rem;
              margin-left: 1rem;
            }
          }
        }
        .card-desc {
          display: flex;
          justify-content: flex-start;
          max-width: 35rem;
          .desc {
            font-family: "Novel Mono Pro";
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2.4rem;
            letter-spacing: -0.02em;
            color: #303031;
          }
        }
      }
      .applicant-info {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 3rem;

        .applicants {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          background: #f3f0ea;
          border: 0.4rem solid #303031;
          border-radius: 1.6rem;
          width: fit-content;
          height: auto;
          padding: 2rem;

          .applicants-heading {
            font-family: "Novel Mono Pro";
            font-style: normal;
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 3.2rem;
            letter-spacing: -0.02em;
            color: #303031;
          }
          .applicants-list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .each-applicant {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-top: 1rem;

              .applicant-img {
                align-items: flex-start;
                display: flex;
                justify-content: flex-start;

                svg {
                  border-radius: 50%;
                }
              }
              .applicant-name {
                font-family: "Novel Mono Pro";
                font-style: normal;
                font-weight: 600;
                font-size: 2rem;
                line-height: 2.4rem;
                letter-spacing: -0.02em;
                color: #303031;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin-left: 1rem;
              }
              .rating-star {
                width: 2.4rem;
                height: 2.4rem;
                border-radius: 0.1rem;
                margin-left: 2rem;
              }
              .applicant-rating {
                font-family: "Novel Mono Pro";
                font-style: normal;
                font-weight: 500;
                font-size: 2rem;
                line-height: 2.4rem;
                color: #303031;
                margin-left: 1rem;
              }
            }
          }
        }
        .applicant-detail-card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          border: 0.4rem solid #ff1493;
          border-radius: 1.6rem;
          width: fit-content;
          height: auto;
          margin-top: 2rem;
          margin-left: 1rem;
          padding: 2rem;

          .applicant-intro {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-self: center;
            align-items: center;

            .applicant-img {
              align-items: center;
              display: flex;
              justify-content: center;

              svg {
                border-radius: 50%;
              }
            }
            .applicant-name {
              font-family: "Novel Mono Pro";
              font-style: normal;
              font-weight: 700;
              font-size: 2rem;
              line-height: 2.4rem;
              text-align: center;
              letter-spacing: -0.02em;
              color: #ff1493;
              padding-top: 1rem;
            }
            .applicant-role {
              font-family: "Novel Mono Pro";
              font-style: normal;
              font-weight: 500;
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
              letter-spacing: -0.02em;
              color: #66645e;
              padding-top: 1rem;
            }
          }
          .score-row {
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: center;
            padding-top: 3rem;

            .commits {
              font-family: "Novel Mono Pro";
              font-style: normal;
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 2.4rem;
              letter-spacing: -0.02em;
              color: #303031;
            }
            .rating-star {
              width: 2.4rem;
              height: 2.4rem;
              border-radius: 0.1rem;
              margin-left: 6rem;
            }
            .applicant-rating {
              font-family: "Novel Mono Pro";
              font-style: normal;
              font-weight: 500;
              font-size: 2rem;
              line-height: 2.4rem;
              letter-spacing: -0.02em;
              color: #303031;
              margin-left: 0.1rem;
            }
          }
          .view-profile-tab {
            display: flex;
            align-items: center;
            align-self: center;
            padding-top: 1rem;

            .action-btn {
              background: #ff1493;
              border-radius: 1.6rem;
              padding: 1.2rem 2.4rem;
              font-family: "Novel Mono Pro";
              font-style: normal;
              font-weight: 500;
              font-size: 1.6rem;
              line-height: 2rem;
              letter-spacing: -0.02em;
              color: #ffffff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
