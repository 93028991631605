.member-section {
  // background-color: #FFBCD9;
  padding: 10rem 0rem;
  .page-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 10rem;
    justify-content: center;
    margin: 0rem 5rem;

    .section-left {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .left-Icon {
        background: #f3f0ea;
        border-radius: 10rem;
        align-self: flex-start;
        width: fit-content;
        width: 5rem;
        height: 5rem;
        align-items: center;
        display: flex;
        margin: 1rem;
      }
      .left-heading {
        display: flex;
        font-family: "Bely Display";
        font-style: normal;
        font-weight: 400;
        font-size: 4.8rem;
        line-height: 6.4rem;
        color: #303031;
      }
      .left-content {
        display: flex;
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.6rem;
        letter-spacing: -0.02em;
        color: #303031;
      }
    }

    .bg-party {
      display: flex;
      align-items: center;
      justify-content: center;
      .party-girl1 {
        max-width: 80%;
        margin: auto;
      }
    }
    .section-right {
      display: flex;
      position: relative;
      justify-self: center;
      padding-bottom: 2rem;

      .name-card {
        background: #ffffff;
        border: 0.4rem solid #303031;
        border-radius: 1.6rem;
        min-width: 40rem;
        position: relative;
        z-index: 100;
        box-shadow: 1rem 1rem 0.15rem #ff1493;
        padding: 1rem;

        .name-card-heading {
          font-family: "Novel Mono Pro";
          font-style: normal;
          font-weight: 700;
          font-size: 3.2rem;
          line-height: 4rem;
          color: #303031;
          padding: 1rem 2rem;
        }
        .name-card-content {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 2rem;
          .member-group {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .member-img {
              overflow: hidden;
              width: 10rem;
              height: 10rem;
              align-items: center;
              justify-content: center;
              display: flex;
              align-self: center;
              align-items: center;
              margin-right: 1rem;
              margin-left: 1rem;

              svg {
                border-radius: 50%;
              }
            }
            .member-info {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              flex-direction: column;

              .member-name {
                width: 15.4rem;
                height: 2.6rem;
                font-family: "Novel Mono Pro";
                font-style: normal;
                font-weight: 600;
                font-size: 2.4rem;
                line-height: 2.6rem;
                letter-spacing: -0.02em;
                color: #303031;
              }
              .member-shares {
                font-family: "Novel Mono Pro";
                font-style: normal;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2.6rem;
                letter-spacing: -0.02em;
                color: #66645e;
              }
            }
          }
        }
      }
    }
  }
}
