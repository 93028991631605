.event-list-section {
  display: flex;
  padding: 10rem 5rem 20rem 5rem;
  flex-direction: column;
  clip-path: polygon(0 5rem, 100% 0, 100% 100%, 0% 100%);
  background: linear-gradient(
    to right,
    rgba(255, 219, 229, 0.7),
    rgba(255, 194, 211, 0.9)
  );

  .section-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    .upcoming-events {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 700;
      font-size: 4rem;
      line-height: 4.9rem;
      color: #242565;
    }

    .event-filters {
      display: flex;
      justify-content: center;
      align-items: center;

      .filter-type {
        background: #f2f4ff;
        border-radius: 5rem;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: #1d275f;
        margin: 2rem;
        text-align: center;
      }
    }
  }
  .events {
    display: flex;
    flex-direction: column;

    .events-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 8rem;

      .event-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        justify-self: center;
        width: 100%;
        max-width: 30rem;
        box-shadow: 1rem 1rem 1rem rgba(#7773aa, 10%);
        border-radius: 1rem;
        background-color: white;

        .event-img {
          max-height: 30rem;
          overflow: hidden;
          border-radius: 1.8rem 1.8rem 0rem 0rem;
          background-color: white;
          img {
            object-fit: cover;
            width: 100%;
            align-self: center;
          }
        }
        .event-desc {
          display: flex;
          padding: 2rem;
          width: 100%;
          background-color: white;
          border-radius: 0rem 0rem 1.8rem 1.8rem;

          .event-date {
            display: flex;
            flex-direction: column;

            .month {
              font-family: "DM Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 1.13717rem;
              line-height: 1.5rem;
              text-align: center;
              color: #3d37f1;
            }
            .date {
              font-family: "DM Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 2.84293rem;
              line-height: 3.7rem;
              color: #000000;
            }
          }

          .event-details {
            display: flex;
            flex-direction: column;
            margin-left: 2rem;

            .detail-heading {
              font-family: "DM Sans";
              font-style: normal;
              font-weight: 700;
              font-size: 1.6rem;
              line-height: 150%;
              color: #000000;
            }
            .detail {
              font-family: "DM Sans";
              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 150%;
              color: #6a6a6a;
            }
          }
        }
      }
    }
  }
  .action-btn {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 3rem;

    .load-more-btn {
      border: 0.15rem solid #3d37f1;
      filter: drop-shadow(0rem 1rem 5rem rgba(61, 55, 241, 0.25));
      border-radius: 5rem;
      background-color: transparent;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.3rem;
      text-align: center;
      color: #3d37f1;
      width: 15rem;
      padding: 1rem;
    }
  }
}
