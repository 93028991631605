@media screen and (max-width: 1500px) {
  :root {
    font-size: 50%;
  }
}

@media screen and (max-width: 1200px) {
  :root {
    font-size: 45%;
  }
}

@media screen and (max-width: 1000px) {
  :root {
    font-size: 40%;
  }
}

@media screen and (max-width: 800px) {
  .header {
    .tagline {
      background: none;
      grid-template-columns: 1fr;
      .tagline-left {
        text-align: center;
        .action-btn {
          margin: auto;
        }

        .header-text {
          font-size: 5rem;
          line-height: normal;
        }

        .desc-text {
          max-width: 400px;
          margin: auto;
        }
      }
      .tagline-right {
        display: none;
      }
    }
    .title-bar {
      .nav {
        display: none;
      }
      .nav-alt {
        display: block;
      }
    }
  }
  .card-section {
    .desc-text {
      margin-bottom: 2rem;
    }
    .card-row {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 3rem;

      .card-item {
        max-width: 50rem;
        margin: auto;
      }
    }
  }
  .member-section {
    .page-body {
      grid-template-columns: 1fr;
      .section-left {
        text-align: center;
        max-width: 60rem;
        margin: auto;

        .left-heading {
          margin-bottom: 2rem;
          font-size: 3.5rem;
          line-height: normal;
        }

        & > * {
          margin: auto !important;
        }
      }
    }
  }
  .onboard-user-parent {
    .user-onboarding {
      grid-template-columns: 1fr;
      grid-gap: 5rem;

      & > * {
        margin: auto;
      }

      .bg-party {
        display: none;
      }

      .right-body {
        text-align: center;
        max-width: 60rem;
        padding: 3rem;

        & > * {
          margin: auto !important;
        }
      }
    }
  }
  .parent-tag {
    .contributors-section {
      grid-template-columns: 1fr;
      grid-gap: 5rem;

      .section-content {
        text-align: center;
        max-width: 60rem;
        margin: auto;
        padding: 3rem;
        & > * {
          margin: auto !important;
        }

        .section-logo {
          justify-content: center;
        }
      }
    }
  }
  .how-to-improve-parent {
    .how-to-improve {
      .helping-cards {
        grid-template-columns: 1fr;
        flex-direction: column;

        .each-card {
          max-width: 60rem;
          margin: auto;
        }

        .each-card:last-child {
          margin-bottom: 4rem;
        }
      }
    }
  }
  .start-enterprise-parent {
    .start-enterprise-section {
      .content-grid {
        background: none;
        grid-template-columns: 1fr;

        .left-section {
          text-align: center;
          .request-access-btn {
            align-items: center;
            justify-content: center;
          }

          .text-content {
            text-align: center;
            margin: auto;
            .content-head,
            .content-desc {
              width: 100%;
            }
          }
        }

        .right-section {
          display: none;
        }
      }
      .end-line {
        padding: 1rem 0rem;
        .watermark {
          display: none;
        }
        align-items: center;
        justify-content: center;
      }
    }
  }

  .make-own-event {
    .left-img {
      img {
        position: unset;
      }
    }
  }

  .header-parent {
    .head-row {
      grid-template-columns: 1fr;
      .nav {
        display: none;
      }
      .nav-alt {
        display: block;
      }
    }
    .event-tickets {
      grid-template-columns: 1fr;
    }
  }

  .event-list-section {
    .events {
      .events-row {
        grid-template-columns: 1fr;
      }
    }
  }

  .make-own-event {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    .left-img {
      grid-row-start: 2;
      margin: auto;
    }

    .right-content {
      text-align: center;
    }
  }

  .footer {
    .end-line {
      justify-content: center;
      .watermark {
        display: none;
      }

      .events {
        align-items: center;
        justify-content: center;
      }
    }
  }

  .sign-up,
  .login,
  .vaidate-email,
  .reset-pswd,
  .reset-pwd-action {
    .top-left,
    .top-right,
    .bottom-left,
    .bottom-right {
      display: none;
    }
  }
}
