.onboard-user-parent {
  background: linear-gradient(
    to right,
    rgba(255, 219, 229, 0.7),
    darken(rgba(255, 219, 229, 0.9), 5%)
  );
  clip-path: polygon(0 5rem, 100% 0, 100% calc(100% - 5rem), 0% 100%);
  padding: 10rem 0rem;

  .user-onboarding {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0rem 5rem;
    grid-gap: 3rem;

    .bg-party {
      .party-girl1 {
        max-width: 80%;
        margin: auto;
      }
    }

    .user-profile-card {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background: #ffffff;
      border: 0.4rem solid #303031;
      border-radius: 1.6rem;
      max-width: 48.4rem;
      height: 41.2rem;
      flex-direction: column;
      padding: 3rem;

      .user-intro-grid {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        grid-gap: 2rem;

        .user-img {
          overflow: hidden;
          border-radius: 50%;
          align-self: top;
          width: 10rem;
          align-items: flex-start;
          display: flex;
          justify-content: flex-start;
        }
        .user-intro {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .user-name {
            font-family: "Novel Mono Pro";
            font-style: normal;
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 3.2rem;
            letter-spacing: -0.02em;
            color: #ff1493;
          }
          .user-class {
            font-family: "Novel Mono Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.4rem;
            letter-spacing: -0.02em;
            color: #66645e;
          }
        }
      }
      .user-msg {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        align-self: center;
        font-family: "Novel Mono Pro";
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.4rem;
        letter-spacing: -0.02em;
        color: #303031;
      }
      .user-value-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5rem;
        align-items: flex-start;
        align-self: flex-start;

        .user-value {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .value-param-name {
            font-family: "Novel Mono Pro";
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2.4rem;
            letter-spacing: -0.02em;
            color: #66645e;
          }
          .value-param {
            font-family: "Novel Mono Pro";
            font-style: normal;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 3.2rem;
            letter-spacing: -0.02em;
            color: #303031;
          }
        }
      }
      .vote-tab {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: flex-start;
        width: 100%;

        .action-btn {
          background: #ff1493;
          border-radius: 1.6rem;
          padding: 1.2rem 2.4rem;
          font-family: "Novel Mono Pro";
          font-style: normal;
          font-weight: 500;
          font-size: 2.4rem;
          line-height: 3.2rem;
          letter-spacing: -0.02em;
          color: #ffffff;
          cursor: pointer;
          width: 100%;
        }
      }
    }
    .right-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .onboarding-logo {
        background: #f3f0ea;
        border-radius: 10rem;
      }
      .msg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .msg-heading {
          font-family: "Bely Display";
          font-style: normal;
          font-weight: 400;
          font-size: 4.8rem;
          line-height: 6.4rem;
          color: #303031;
        }
        .description {
          font-family: "Proxima Nova";
          font-style: normal;
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 2.6rem;
          letter-spacing: -0.02em;
          color: #303031;
        }
      }
    }
  }
}
