.home {
}
.highlight {
  font-family: "Bely Display";
  font-style: normal;
  font-weight: 500;
  font-size: 6.4rem;
  line-height: 8rem;
  color: #ff1493;
}
.highlight-j {
  font-family: "Bely Display";
  font-style: normal;
  font-weight: 500;
  font-size: 4.8rem;
  line-height: 6.4rem;
  color: #ff1493;
}

.highlight-text {
  color: #ff1493;
}


.f-120p {
  font-size: 120%;
}

.conjusted {
  letter-spacing: -0.4rem;
  transition: all 0.2s;
}

.conjusted:hover {
  letter-spacing: 0rem;
}

.dance {
  animation: animate 1s infinite;
  & > span {
    display: table-cell;
    animation: animate1 1s infinite;
  }

  @keyframes animate {
    0% {
      letter-spacing: -0.4rem;
    }

    50% {
      letter-spacing: -0.2rem;
    }

    100% {
      letter-spacing: -0.5rem;
    }
  }

  @keyframes animate1 {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0px);
    }
  }

  & > span:nth-child(1) {
    animation-delay: 0.1s;
  }

  & > span:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > span:nth-child(3) {
    animation-delay: 0.3s;
  }

  & > span:nth-child(4) {
    animation-delay: 0.4s;
  }

  & > span:nth-child(5) {
    animation-delay: 0.5s;
  }

  & > span:nth-child(6) {
    animation-delay: 0.6s;
  }

  & > span:nth-child(7) {
    animation-delay: 0.7s;
  }

  & > span:nth-child(8) {
    animation-delay: 0.8s;
  }

  & > span:nth-child(9) {
    animation-delay: 0.9s;
  }

  & > span:nth-child(10) {
    animation-delay: 1s;
  }

  & > span:nth-child(11) {
    animation-delay: 1.1s;
  }

  & > span:nth-child(12) {
    animation-delay: 1.2s;
  }

  & > span:nth-child(13) {
    animation-delay: 1.3s;
  }
}

.dance-rev {
  animation: animate 1s infinite;
  & > span {
    display: table-cell;
    animation: animate1 1s infinite;
  }

  @keyframes animate {
    0% {
      letter-spacing: -0.4rem;
    }

    50% {
      letter-spacing: -0.2rem;
    }

    100% {
      letter-spacing: -0.5rem;
    }
  }

  @keyframes animate1 {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(10px);
    }

    100% {
      transform: translateY(0px);
    }
  }

  & > span:nth-child(1) {
    animation-delay: 0.1s;
  }

  & > span:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > span:nth-child(3) {
    animation-delay: 0.3s;
  }

  & > span:nth-child(4) {
    animation-delay: 0.4s;
  }

  & > span:nth-child(5) {
    animation-delay: 0.5s;
  }

  & > span:nth-child(6) {
    animation-delay: 0.6s;
  }

  & > span:nth-child(7) {
    animation-delay: 0.7s;
  }

  & > span:nth-child(8) {
    animation-delay: 0.8s;
  }

  & > span:nth-child(9) {
    animation-delay: 0.9s;
  }

  & > span:nth-child(10) {
    animation-delay: 1s;
  }

  & > span:nth-child(11) {
    animation-delay: 1.1s;
  }

  & > span:nth-child(12) {
    animation-delay: 1.2s;
  }

  & > span:nth-child(13) {
    animation-delay: 1.3s;
  }
}
