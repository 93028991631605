.header-parent {
  padding-left: 5rem;
  padding-right: 5rem;
  flex-direction: column;

  .head-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      display: flex;
      align-items: center;
      cursor: pointer;

      .logo-img {
        margin-right: 0.5rem;
        img {
          height: 10rem;
        }
      }
      .logo-text {
        font-family: cursive;
        font-style: normal;
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 4.3rem;
        color: #303031;
      }
    }

    .nav-alt {
      display: none;

      .toggle-btn {
        font-size: 3rem;
        cursor: pointer;
        text-align: right;
        padding: 1rem 2rem;
      }

      .menu-box {
        background: #fff;
        box-shadow: 0.1rem 0 2rem 0 #fff;
        display: block;
        height: 100vh;
        overflow: auto;
        position: fixed;
        right: 0;
        top: 0;
        transition: all 0.3s ease-in-out;
        max-width: 100vw;
        min-width: 250px;
        z-index: 1250;

        .nav-alt-list {
          list-style-type: none;
          margin-top: 2rem;
          li {
            padding: 0.5rem 1rem;
            font-size: 3rem;
            margin-bottom: 2rem;
          }
        }

        .highlighted {
          background-color: #ff1493;
          color: white;
        }
      }
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .nav-list {
        display: flex;
        justify-content: space-between;
        list-style-type: none;

        li {
          display: inline-block;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 2.2rem;
          padding: 0rem 1.5rem;
          cursor: pointer;
        }

        .highlighted {
          color: #ff1493;
        }
      }

      .nav-list-right {
        list-style-type: none;

        li {
          display: inline-block;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 2.2rem;
          padding: 0rem 1.5rem;
          cursor: pointer;
        }

        .login-btn {
          padding: 0.5rem 1rem;
          background: #ff1493;
          font-family: "Proxima Nova";
          font-style: normal;
          font-size: 1.8rem;
          color: #ffffff;
          width: fit-content;
          border-radius: 0.8rem;
          cursor: pointer;
        }

        .login-btn:hover {
          background: darken(#ff1493, 20%);
        }
      }
    }
  }

  .event-tickets {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: 5rem;
    margin: 5rem 0rem;
    align-items: center;

    .event-logo {
      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .event-detail {
      align-self: center;
      justify-self: center;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      align-items: center;
      justify-content: center;

      h1 {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-size: 4rem;
        line-height: 4.9rem;
      }

      p {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 130%;
      }
    }
  }
}
