.header {
  margin: 0rem 5rem;
  .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      display: flex;
      align-items: center;
      cursor: pointer;
      .logo-img {
        margin-right: 0.5rem;
        img {
          height: 10rem;
        }
      }
      .logo-text {
        font-family: cursive;
        font-style: normal;
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 4.3rem;
        color: #303031;
      }
    }

    .nav-alt {
      display: none;

      .toggle-btn {
        font-size: 3rem;
        cursor: pointer;
        text-align: right;
        padding: 1rem 2rem;
      }

      .menu-box {
        background: #fff;
        box-shadow: 0.1rem 0 2rem 0 #fff;
        display: block;
        height: 100vh;
        overflow: auto;
        position: fixed;
        right: 0;
        top: 0;
        transition: all 0.3s ease-in-out;
        max-width: 100vw;
        min-width: 250px;
        z-index: 1250;

        .nav-alt-list {
          list-style-type: none;
          margin-top: 2rem;
          li {
            padding: 0.5rem 1rem;
            font-size: 3rem;
            margin-bottom: 2rem;
          }
        }

        .highlighted {
          background-color: #ff1493;
          color: white;
        }
      }
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .nav-list {
        display: flex;
        justify-content: space-between;
        list-style-type: none;

        li {
          display: inline-block;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 2.2rem;
          padding: 0rem 1.5rem;
          cursor: pointer;
        }

        .highlighted {
          color: #ff1493;
        }
      }

      .nav-list-right {
        list-style-type: none;

        li {
          display: inline-block;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 1.8rem;
          line-height: 2.2rem;
          padding: 0rem 1.5rem;
          cursor: pointer;
        }

        .login-btn {
          padding: 0.5rem 1rem;
          background: #ff1493;
          font-family: "Proxima Nova";
          font-style: normal;
          font-size: 1.8rem;
          color: #ffffff;
          width: fit-content;
          border-radius: 0.8rem;
          cursor: pointer;
        }

        .login-btn:hover {
          background: darken(#ff1493, 20%);
        }
      }
    }
  }

  .tagline {
    padding: 10rem 0rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
    background: url("../../assets/svgs/hero.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    align-items: center;

    .tagline-left {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      align-items: center;
      justify-content: center;

      h1 {
        font-family: "Bely Display";
        font-style: normal;
        font-weight: 400;
        font-size: 6.4rem;
        line-height: 8rem;
      }

      p {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 2.4rem;
        line-height: 3.2rem;
      }

      .action-btn {
        padding: 2rem 2.4rem;
        background: #ff1493;
        font-family: "Proxima Nova";
        font-style: normal;
        font-size: 1.8rem;
        line-height: 2.4rem;
        color: #ffffff;
        width: fit-content;
        letter-spacing: 0.1rem;
        border-radius: 0.8rem;
        cursor: pointer;
        transition: top 0.5s;
      }

      .action-btn:hover {
        background: darken(#ff1493, 20%);
        position: relative;
        top: -0.1rem;
      }
    }
  }
}
