.how-to-improve-parent {
  background: linear-gradient(
    to right,
    rgba(255, 219, 229, 0.7),
    darken(rgba(255, 219, 229, 0.9), 5%)
  );
  padding: 10rem 0rem;
  clip-path: polygon(0 5rem, 100% 0, 100% calc(100% - 5rem), 0% 100%);

  .how-to-improve {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 0rem 5rem;

    .page-heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: center;
      max-width: 80rem;

      .primary-heading {
        font-family: "Bely Display";
        font-style: normal;
        font-weight: 400;
        font-size: 4.8rem;
        line-height: 6.4rem;
        text-align: center;
        color: #303031;
      }
      .heading-desc {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.6rem;
        text-align: center;
        letter-spacing: -0.02em;
        color: #303031;
      }
    }

    .helping-cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 3rem;
      padding-top: 5rem;
      align-self: center;
      width: 100%;

      .each-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #ffffff;
        box-shadow: 0rem 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
        border-radius: 1.6rem;
        padding: 2rem;
        margin: 2rem;

        .card-info {
          .card-heading {
            font-family: "Novel Mono Pro";
            font-style: normal;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 2.4rem;
            letter-spacing: -0.02em;
            color: #303031;
          }
          .card-desc {
            font-family: "Novel Mono Pro";
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2.4rem;
            letter-spacing: -0.02em;
            color: #303031;
            padding-top: 1rem;
          }
        }
        .bottom-line {
          padding-top: 2rem;
          width: 100%;

          .apply-by {
            font-family: "Novel Mono Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.4rem;
            text-align: right;
            letter-spacing: -0.02em;
            color: #303031;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
          }
        }
      }
    }
    .view-more-button {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      margin-top: 1rem;

      .action-btn {
        background: #ff1493;
        border-radius: 0.8rem;
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.4rem;
        text-align: center;
        color: #ffffff;
        padding: 1rem 2rem;
      }

      .action-btn:hover {
        background: darken(#ff1493, 20%);
        position: relative;
        top: -0.1rem;
      }
    }
  }
}
