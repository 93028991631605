.make-own-event {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #eee1ff;
  align-items: center;
  padding: 0rem 5rem;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: left;
  align-items: end;

  .left-img {
    position: relative;
    img {
      position: absolute;
      min-width: 20vw;
      max-width: 40vw;
      left: 0;
      bottom: 0;
    }
  }

  .right-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: center;
    padding: 5rem 2rem;

    .text-content {
      padding-bottom: 2rem;
      width: 100%;

      .content-heading {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 3.4rem;
        line-height: 4.4rem;
        color: #000000;
      }

      .content-desc {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.3rem;
        color: #272727;
      }
    }

    .action-btn {
      width: 100%;

      .create-event-btn {
        background: #f5167e;
        box-shadow: 0rem 1rem 5rem rgba(61, 55, 241, 0.25);
        border-radius: 5rem;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.3rem;
        text-align: center;
        color: #ffffff;
        padding: 1rem;
        width: 20rem;
      }
    }
  }
}
