.pageWrapper {
  min-height: 100vh;
  background-color: white;
}
.contentArea {
  flex-grow: 1;
  width: calc(100vw - 26rem);
}
.card {
  box-shadow: $card-box-shadow;
  margin-bottom: 3rem;
}

.circle-box {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  border-radius: 100%;
  &.lg-box {
    width: 6rem;
    height: 6rem;
    font-size: 2.1rem;
    line-height: 6rem;
  }
}
.button-group .btn {
  margin: 0.3rem;
}

.main-content,
.nav-content {
  max-height: calc(100vh - 7rem);
  overflow: auto;
}

.height-6rem {
  height: 6rem !important;
}
