.footer {
  display: flex;
  margin: 0rem 5rem;
  padding: 2rem 0rem;
  flex-direction: column;

  .end-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
    padding: 1rem 0rem;
    width: 100%;

    .watermark {
      display: flex;
      align-items: center;
      font-family: cursive;
      font-style: normal;
      font-weight: 500;
      font-size: 3.2rem;
      line-height: 4.3rem;
      color: #303031;
    }
    .links {
      .links-list {
        display: flex;
        justify-content: space-between;
        list-style-type: none;

        li {
          display: inline-block;
          font-family: "Proxima Nova";
          font-style: normal;
          font-weight: 500;
          font-size: 2rem;
          line-height: 2.4rem;
          letter-spacing: -0.02em;
          color: #303031;
          padding: 0rem 1.5rem;
          cursor: pointer;
        }

        .highlighted {
          color: #ff1493;
        }
      }
    }
  }

  .copyright {
    display: flex;
    align-self: center;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Bely Display";
    padding-top: 0.5rem;
  }
}
