.all-blogs {
  max-height: 350px;
  overflow: auto;
}

.RichEditor-root {
  background: #fff;
  font-family: Arial, "Georgia", serif;
  font-size: 14px;
  padding: 15px;
  padding-top: 0px;
}

.RichEditor-root img {
  max-width: 600px;
}

.RichEditor-editor {
  cursor: text;
  font-size: 16px;
}

.RichEditor-control-css {
  border-top: 1px solid #ddd;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: Arial, "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

pre > pre {
  margin: 0;
}

.blog-title {
  font-size: 35px;
  letter-spacing: 3px;
  color: black;
  font-weight: 100;
}

.split-1-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
