.start-enterprise-parent {
  // background-color: #FFBCD9;
  padding-bottom: 2rem;

  .start-enterprise-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: flex-start;
    padding-top: 10rem;
    margin: 0rem 5rem;

    .content-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background: url("../../assets/svgs/pabloSignIn.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
      width: 100%;
      align-items: center;

      .left-section {
        padding: 10rem 0rem;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 2rem;
        align-items: flex-start;
        justify-content: flex-start;

        .text-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-self: flex-start;
          align-items: flex-start;
          max-width: 70rem;

          .content-head {
            font-family: "Bely Display";
            font-style: normal;
            font-weight: 400;
            font-size: 4.8rem;
            line-height: 6.4rem;
            color: #ff1493;
          }
          .content-desc {
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.6rem;
            letter-spacing: -0.02em;
            color: #303031;
          }
        }
        .request-access-btn {
          display: flex;
          justify-content: flex-start;
          align-self: flex-start;
          align-items: flex-start;

          .action-btn {
            padding: 2rem 2.4rem;
            background: #ff1493;
            font-family: "Proxima Nova";
            font-style: normal;
            font-size: 1.8rem;
            line-height: 2.4rem;
            color: #ffffff;
            width: fit-content;
            letter-spacing: 0.1rem;
            border-radius: 0.8rem;
            cursor: pointer;
            transition: top 0.5s;
          }

          .action-btn:hover {
            background: darken(#ff1493, 20%);
            position: relative;
            top: -0.1rem;
          }
        }
      }
      .right-section {
      }
    }

    .end-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: flex-start;
      padding: 1rem 0rem;
      width: 100%;

      .watermark {
        display: flex;
        align-items: center;
        font-family: cursive;
        font-style: normal;
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 4.3rem;
        color: #303031;
      }
      .links {
        .links-list {
          display: flex;
          justify-content: space-between;
          list-style-type: none;

          li {
            display: inline-block;
            font-family: "Proxima Nova";
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 2.4rem;
            letter-spacing: -0.02em;
            color: #303031;
            padding: 0rem 1.5rem;
            cursor: pointer;
          }

          .highlighted {
            color: #ff1493;
          }
        }
      }
    }
    .copyright {
      display: flex;
      align-self: center;
      font-size: 1.5rem;
      font-weight: 600;
      font-family: "Bely Display";
      padding-top: 0.5rem;
    }
  }
}
