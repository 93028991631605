.card-section {
  padding: 10rem 0rem;
  clip-path: polygon(0 5rem, 100% 0, 100% calc(100% - 5rem), 0% 100%);
  background: linear-gradient(
    to right,
    rgba(255, 219, 229, 0.7),
    darken(rgba(255, 219, 229, 0.9), 5%)
  );
  .card-heading {
    margin-bottom: 5rem;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    max-width: 70rem;

    .header-text {
      font-family: "Bely Display";
      font-style: normal;
      font-weight: 400;
      font-size: 4.8rem;
      line-height: 6.4rem;
      text-align: center;
      color: #303031;
      align-items: center;
      margin-bottom: 3rem;
    }
    .desc-text {
      font-family: "Proxima Nova";
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.6rem;
      text-align: center;
      letter-spacing: -0.02em;
      color: #303031;
    }
  }
  .card-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    margin: 0rem 5rem;
    padding-bottom: 2rem;

    .card-item {
      padding: 0rem 3rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0rem 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
      border-radius: 0.8rem;

      .card-img {
        background: #f3f0ea;
        align-self: center;
        width: fit-content;
        border-radius: 50%;
        width: 10rem;
        height: 10rem;
        align-items: center;
        justify-content: center;
        display: flex;
        margin: 1rem;
      }

      .card-title {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 3.2rem;
        text-align: center;
        color: #303031;
      }

      .card-desc {
        font-family: "Proxima Nova";
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.6rem;
        text-align: center;
        color: #303031;
      }
    }
  }
}
